import { Box, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createApp,
  deleteAppFromDB,
  updateAppGeneralInfo,
} from "../../../common/api/general.api";
import { AlertContext } from "../../../common/contexts";
import { IApp } from "../../../common/interfaces";
import { generalInfoSchema } from "../../../common/validation/app-validation-schema";
import SectionActionBar from "../../../components/SectionActionBar/SectionActionBar";

function GeneralInfo({
  isNew,
  id,
  displayAppName,
  logicAppName,
  packageId,
  keywords,
  newsPushTopic,
  setAppDetails,
  setIsNew,
}: any) {
  const { showSuccess, showError } = useContext(AlertContext);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      displayAppName,
      logicAppName,
      packageId,
      keywordsStr: keywords.join(","),
      newsPushTopic,
    } as IApp,
    onSubmit: (values) => {
      isNew ? createNewApp(values) : updateExistingApp(values);
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: generalInfoSchema,
    enableReinitialize: true,
  });

  async function deleteApp() {
    setLoading(true);
    try {
      await deleteAppFromDB(id);
      showSuccess(`${displayAppName} app was succesfully removed`);
      navigate("/apps");
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  }

  function resetGeneralInfoForm(values: any) {
    setAppDetails((prevState: IApp) => {
      values.keywords = (values.keywordsStr || "").split(",");
      return {
        ...prevState,
        ...values,
      };
    });
    formik.resetForm();
  }

  async function updateExistingApp(values: any) {
    setLoading(true);
    const { displayAppName, keywordsStr, newsPushTopic } = formik.values;
    try {
      await updateAppGeneralInfo({
        id,
        displayAppName,
        newsPushTopic,
        keywords: (keywordsStr || "").split(","),
      });
      showSuccess(`${displayAppName} app was succesfully updated`);
      resetGeneralInfoForm(values);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  }

  async function createNewApp(values: any) {
    setLoading(true);

    const {
      displayAppName,
      logicAppName,
      packageId,
      keywordsStr,
      newsPushTopic,
    } = formik.values;
    try {
      const newAppId = await createApp({
        packageId,
        displayAppName,
        keywords: (keywordsStr || "").split(","),
        logicAppName,
        newsPushTopic,
        pushTimeFrame: {
          fromHours: 8,
          toHours: 23,
          countryExtraTime: 2,
        },
      });
      showSuccess(`${displayAppName} app was succesfully created`);
      navigate(`/apps/${newAppId}`, { replace: true });
      navigate(0);
      resetGeneralInfoForm(values);
      setIsNew(false);
      // navigate(0);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={loading}
            fullWidth
            id="displayAppName"
            label="App name"
            name="displayAppName"
            error={!!formik.errors.displayAppName}
            helperText={
              !!formik.errors.displayAppName ? formik.errors.displayAppName : ""
            }
            value={formik.values.displayAppName}
            onChange={(event) => {
              formik.setFieldError("displayAppName", "");
              formik.handleChange(event);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={loading || !isNew}
            fullWidth
            id="logicAppName"
            label="Logic app name"
            name="logicAppName"
            error={!!formik.errors.logicAppName}
            helperText={
              !!formik.errors.logicAppName ? formik.errors.logicAppName : ""
            }
            value={formik.values.logicAppName}
            onChange={(event) => {
              formik.setFieldError("logicAppName", "");
              formik.handleChange(event);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={loading || !isNew}
            name="packageId"
            error={!!formik.errors.packageId}
            helperText={
              !!formik.errors.packageId ? formik.errors.packageId : ""
            }
            fullWidth
            id="packageId"
            label="Package Id"
            value={formik.values.packageId}
            autoFocus
            onChange={(event) => {
              formik.setFieldError("packageId", "");
              formik.handleChange(event);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={loading}
            fullWidth
            id="newsPushTopic"
            label="News push topic"
            name="newsPushTopic"
            error={!!formik.errors.newsPushTopic}
            helperText={
              !!formik.errors.newsPushTopic ? formik.errors.newsPushTopic : ""
            }
            value={formik.values.newsPushTopic}
            onChange={(event) => {
              formik.setFieldError("newsPushTopic", "");
              formik.handleChange(event);
            }}
            InputLabelProps={{ shrink: !!formik.values.newsPushTopic }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            disabled={loading}
            name="keywordsStr"
            label="Keywords"
            id="keywordsStr"
            error={!!formik.errors.keywordsStr}
            helperText={
              !!formik.errors.keywordsStr
                ? formik.errors.keywordsStr
                : "delimited with ','"
            }
            value={formik.values.keywordsStr}
            onChange={(event) => {
              formik.setFieldError("keywordsStr", "");
              formik.handleChange(event);
            }}
          />
        </Grid>
      </Grid>

      <SectionActionBar
        isNew={isNew}
        onReset={() => formik.resetForm()}
        onDelete={deleteApp}
        loading={loading}
        shouldDisplayDelete={!isNew}
        formDirty={formik.dirty}
        formTouched={formik.touched}
        submitTextButton={isNew ? "Create app" : "Save"}
      />
    </form>
  );
}

export default GeneralInfo;
