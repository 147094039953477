import {
  FormControlLabel,
  FormLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import { updateAppAdsSettings } from "../../../common/api/ads.api";
import { AlertContext } from "../../../common/contexts";
import { IAdId, IApp } from "../../../common/interfaces";
import SectionActionBar from "../../../components/SectionActionBar/SectionActionBar";

export default function AdSettings({
  id,
  displayInterstitialAd,
  displayBannerAd,
  adIds,
  setAppDetails,
}: any) {
  const { showSuccess, showError } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const {
    ADMOB_ID,
    BANNER_UNIT_ID,
    INTERSTITIAL_UNIT_ID,
    NATIVE_UNIT_ID,
    ADMOB_ID_IOS,
    BANNER_UNIT_ID_IOS,
    INTERSTITIAL_UNIT_ID_IOS,
    NATIVE_UNIT_ID_IOS,
  } = adIds || {};

  const formik = useFormik({
    initialValues: {
      ADMOB_ID,
      BANNER_UNIT_ID,
      INTERSTITIAL_UNIT_ID,
      NATIVE_UNIT_ID,
      ADMOB_ID_IOS,
      BANNER_UNIT_ID_IOS,
      INTERSTITIAL_UNIT_ID_IOS,
      NATIVE_UNIT_ID_IOS,
      displayInterstitialAd,
      displayBannerAd,
    } as any,
    onSubmit: updateAds,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  async function updateAds(values: any) {
    const {
      ADMOB_ID,
      BANNER_UNIT_ID,
      INTERSTITIAL_UNIT_ID,
      NATIVE_UNIT_ID,
      ADMOB_ID_IOS,
      BANNER_UNIT_ID_IOS,
      INTERSTITIAL_UNIT_ID_IOS,
      NATIVE_UNIT_ID_IOS,
      displayInterstitialAd,
      displayBannerAd,
    } = values;
    setLoading(true);
    try {
      await updateAppAdsSettings(
        id,
        {
          ADMOB_ID,
          BANNER_UNIT_ID,
          INTERSTITIAL_UNIT_ID,
          NATIVE_UNIT_ID,
          ADMOB_ID_IOS,
          BANNER_UNIT_ID_IOS,
          INTERSTITIAL_UNIT_ID_IOS,
          NATIVE_UNIT_ID_IOS,
        },
        displayInterstitialAd,
        displayBannerAd
      );
      showSuccess("Ads settings were updated succesfully");
      resetAdsForm(values);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
      //
    }
  }
  function resetAdsForm(values: any) {
    const {
      ADMOB_ID,
      BANNER_UNIT_ID,
      INTERSTITIAL_UNIT_ID,
      NATIVE_UNIT_ID,
      ADMOB_ID_IOS,
      BANNER_UNIT_ID_IOS,
      INTERSTITIAL_UNIT_ID_IOS,
      NATIVE_UNIT_ID_IOS,
      displayInterstitialAd,
      displayBannerAd,
    } = values;
    setAppDetails((prevState: IApp) => {
      return {
        ...prevState,
        adIds: {
          ADMOB_ID,
          BANNER_UNIT_ID,
          INTERSTITIAL_UNIT_ID,
          NATIVE_UNIT_ID,
          ADMOB_ID_IOS,
          BANNER_UNIT_ID_IOS,
          INTERSTITIAL_UNIT_ID_IOS,
          NATIVE_UNIT_ID_IOS,
        },
        displayBannerAd,
        displayInterstitialAd,
      };
    });
    formik.resetForm();
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <FormControlLabel
            control={
              <Switch
                name="displayInterstitialAd"
                checked={formik.values.displayInterstitialAd}
                onChange={formik.handleChange}
              />
            }
            label="Interstitial"
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <FormControlLabel
            control={
              <Switch
                name="displayBannerAd"
                checked={formik.values.displayBannerAd}
                onChange={formik.handleChange}
              />
            }
            label="Banner"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label="Android admob id"
            name="ADMOB_ID"
            value={formik.values.ADMOB_ID}
            onChange={formik.handleChange}
            InputLabelProps={{ shrink: !!formik.values.ADMOB_ID }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Android interstitial id"
            name="INTERSTITIAL_UNIT_ID"
            value={formik.values.INTERSTITIAL_UNIT_ID}
            onChange={formik.handleChange}
            InputLabelProps={{ shrink: !!formik.values.INTERSTITIAL_UNIT_ID }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Android banner id"
            name="BANNER_UNIT_ID"
            value={formik.values.BANNER_UNIT_ID}
            onChange={formik.handleChange}
            InputLabelProps={{ shrink: !!formik.values.BANNER_UNIT_ID }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Android native id"
            name="NATIVE_UNIT_ID"
            value={formik.values.NATIVE_UNIT_ID}
            onChange={formik.handleChange}
            InputLabelProps={{ shrink: !!formik.values.NATIVE_UNIT_ID }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label="IOS admob id"
            name="ADMOB_ID_IOS"
            value={formik.values.ADMOB_ID_IOS}
            onChange={formik.handleChange}
            InputLabelProps={{ shrink: !!formik.values.ADMOB_ID_IOS }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="IOS interstitial id"
            name="INTERSTITIAL_UNIT_ID_IOS"
            value={formik.values.INTERSTITIAL_UNIT_ID_IOS}
            onChange={formik.handleChange}
            InputLabelProps={{
              shrink: !!formik.values.INTERSTITIAL_UNIT_ID_IOS,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="IOS banner id"
            name="BANNER_UNIT_ID_IOS"
            value={formik.values.BANNER_UNIT_ID_IOS}
            onChange={formik.handleChange}
            InputLabelProps={{
              shrink: !!formik.values.BANNER_UNIT_ID_IOS,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="IOS native id"
            name="NATIVE_UNIT_ID_IOS"
            value={formik.values.NATIVE_UNIT_ID_IOS}
            onChange={formik.handleChange}
            InputLabelProps={{
              shrink: !!formik.values.NATIVE_UNIT_ID_IOS,
            }}
          />
        </Grid>
      </Grid>
      <SectionActionBar
        onReset={() => formik.resetForm()}
        loading={loading}
        shouldDisplayDelete={false}
        formDirty={formik.dirty}
        formTouched={formik.touched}
      />
    </form>
  );
}
