import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import { Stack } from "@mui/system";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Breadcrumbs,
  Snackbar,
} from "@mui/material";
import GeneralInfo from "./GeneralInfo/GeneralInfo";
import NotificationsSettings from "./NotificationsSettings/NotificationsSettings";
import AdSettings from "./AdSettings/AdSettings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { getApplication } from "../../common/api/general.api";
import { IAlertObj, IApp } from "../../common/interfaces";

const theme = createTheme();

export default function AppDetails() {
  const [isNew, setIsNew] = useState(false);
  const [alertObj, setAlertObj] = useState<IAlertObj>({ show: false });
  const [expanded, setExpanded] = React.useState<string | false>("general");

  let { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const [appDetails, setAppDetails] = useState<IApp>({
    displayAppName: "",
    id: "",
    logicAppName: "",
    packageId: "",
    keywords: [],
    pushTimeFrame: {
      fromHours: 0,
      toHours: 0,
      countryExtraTime: 0,
    },
    displayInterstitialAd: false,
    displayBannerAd: false,
    adIds: {},
  });

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setSearchParams({ expand: panel });
    };

  useEffect(() => {
    const panelId = searchParams.get("expand") || "";
    if (["general", "notifications", "ads"].includes(panelId)) {
      setExpanded(panelId);
    } else {
      setExpanded("general");
    }
  }, [searchParams]);

  useEffect(() => {
    async function retrieveAppData(appId: any) {
      const appFromDB = await getApplication(appId);
      setAppDetails(appFromDB);
    }
    if (id) {
      retrieveAppData(id);
    } else {
      setIsNew(true);
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/apps">Back to apps</Link>
          </Breadcrumbs>
        </Box>
        <h2>{appDetails.displayAppName || "New app"}</h2>
        <Stack spacing={2}>
          <Accordion
            expanded={expanded === "general"}
            onChange={handleChange("general")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>General</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GeneralInfo
                id={appDetails.id}
                isNew={isNew}
                setIsNew={setIsNew}
                displayAppName={appDetails.displayAppName}
                logicAppName={appDetails.logicAppName}
                packageId={appDetails.packageId}
                keywords={appDetails.keywords}
                newsPushTopic={appDetails.newsPushTopic}
                setAppDetails={setAppDetails}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            disabled={isNew}
            expanded={expanded === "notifications"}
            onChange={handleChange("notifications")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Notifications</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <NotificationsSettings
                id={id}
                fromHours={appDetails.pushTimeFrame?.fromHours}
                toHours={appDetails.pushTimeFrame?.toHours}
                countryExtraTime={appDetails.pushTimeFrame?.countryExtraTime}
                setAppDetails={setAppDetails}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            disabled={isNew}
            expanded={expanded === "ads"}
            onChange={handleChange("ads")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Ads</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AdSettings
                id={id}
                displayInterstitialAd={appDetails.displayInterstitialAd}
                displayBannerAd={appDetails.displayBannerAd}
                adIds={appDetails.adIds}
                setAppDetails={setAppDetails}
              />
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Stack
          sx={{ marginTop: "20px" }}
          direction={"row"}
          spacing={1}
          justifyContent="flex-end"
        ></Stack>
      </Box>
    </>
  );
}
