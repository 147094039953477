import Parse from "parse";
import { IAdId } from "../interfaces";

export async function updateAppAdsSettings(
  id: string,
  ads: IAdId,
  displayInterstitialAd: boolean,
  displayBannerAd: boolean
) {
  let {
    ADMOB_ID,
    ADMOB_ID_IOS,
    BANNER_UNIT_ID,
    BANNER_UNIT_ID_IOS,
    INTERSTITIAL_UNIT_ID,
    INTERSTITIAL_UNIT_ID_IOS,
    NATIVE_UNIT_ID,
    NATIVE_UNIT_ID_IOS,
  } = ads;

  const parseQuery: Parse.Query = new Parse.Query("App");
  const appFromParse = await parseQuery.get(id || "");
  const adObj = {
    ADMOB_ID,
    ADMOB_ID_IOS,
    BANNER_UNIT_ID,
    BANNER_UNIT_ID_IOS,
    INTERSTITIAL_UNIT_ID,
    INTERSTITIAL_UNIT_ID_IOS,
    NATIVE_UNIT_ID,
    NATIVE_UNIT_ID_IOS,
  };
  appFromParse.set("adIds", adObj);
  appFromParse.set("displayInterstitialAd", displayInterstitialAd);
  appFromParse.set("displayBannerAd", displayBannerAd);

  try {
    await appFromParse.save();
    return true;
  } catch (error: any) {
    throw "Error in updating the app";
  }
}
