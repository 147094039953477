import ContentzMenuDrawer from "../../components/ContentzMenuDrawer/ContentzMenuDrawer";
import FeedbackProvider from "../../components/FeedbackProvider/FeedbackProvider";
import DataResourcesGrid from "./DataResourcesGrid/DataResourcesGrid";

function DataResources() {
  return (
    <ContentzMenuDrawer>
      <FeedbackProvider>
        <DataResourcesGrid />
      </FeedbackProvider>
    </ContentzMenuDrawer>
  );
}

export default DataResources;
