import { Box, Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAppList } from "../../../common/api/general.api";
import { IApp } from "../../../common/interfaces";
import FilterInput from "../../../components/FilterInput/FilterInput";
import Application from "../Application/Application";
import AppListLoader from "./AppListLoader";

export default function AppList() {
  const [allContentzApps, setApps] = useState<IApp[]>([]);
  const [allContentzAppsUnfiltered, setAppsUnfiltered] = useState<IApp[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    async function getAppData() {
      setLoading(true);
      const allApps: IApp[] = await getAppList();
      setApps(allApps as any);
      setAppsUnfiltered(allApps);
      setLoading(false);
    }
    getAppData();
  }, []);

  function onFilterInputChanged(value: string) {
    setFilterText(value);
    if (value?.trim() === "") {
      setApps(allContentzAppsUnfiltered);
    } else {
      const filteredApps = allContentzAppsUnfiltered.filter((app) => {
        return (
          (app.displayAppName?.toLowerCase() || "").includes(
            value.toLowerCase()
          ) ||
          (app.packageId?.toLowerCase() || "").includes(value.toLowerCase()) ||
          (app.logicAppName?.toLowerCase() || "").includes(value.toLowerCase())
        );
      });
      setApps(filteredApps);
    }
  }

  return (
    <Box>
      <Box marginBottom={2}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            {!loading && (
              <FilterInput value={filterText} onChange={onFilterInputChanged} />
            )}
          </Grid>
          <Grid item>
            {!loading && (
              <Button onClick={() => navigate("/apps/new")} variant="contained">
                New app
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        {allContentzApps.map((app, index) => {
          return (
            <Grid key={app.logicAppName} item xs={12} sm={3} md={3}>
              {loading ? <AppListLoader /> : <Application app={app} />}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
