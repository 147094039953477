import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { updateAppNotificationSettings } from "../../../common/api/notification.api";
import { AlertContext } from "../../../common/contexts";
import { IApp, IPushTimeFrame } from "../../../common/interfaces";
import { notificationSchema } from "../../../common/validation/app-validation-schema";
import SectionActionBar from "../../../components/SectionActionBar/SectionActionBar";

function NotificationsSettings({
  id,
  fromHours,
  toHours,
  countryExtraTime,
  setAppDetails,
}: any) {
  const { showSuccess, showError } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      countryExtraTime,
      fromHours,
      toHours,
    } as IPushTimeFrame,
    onSubmit: updateNotifications,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: notificationSchema,
  });

  function resetNotificationsForm(values: any) {
    setAppDetails((prevState: IApp) => {
      return {
        ...prevState,
        pushTimeFrame: {
          ...values,
        },
      };
    });
    formik.resetForm();
  }

  async function updateNotifications(values: any) {
    setLoading(true);
    try {
      await updateAppNotificationSettings(id, {
        fromHours: formik.values.fromHours,
        toHours: formik.values.toHours,
        countryExtraTime: formik.values.countryExtraTime,
      });
      showSuccess("Notification settings were updated succesfully");
      resetNotificationsForm(values);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            id="fromHours"
            label="From hours"
            name="fromHours"
            type="number"
            value={formik.values.fromHours}
            onChange={formik.handleChange}
            InputLabelProps={{ shrink: !!formik.values.fromHours }}
            error={!!formik.errors.fromHours}
            inputProps={{
              min: 8,
              max: 23,
            }}
            helperText={
              !!formik.errors.fromHours ? formik.errors.fromHours : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            id="toHours"
            label="To hours"
            name="toHours"
            type="number"
            value={formik.values.toHours}
            onChange={formik.handleChange}
            inputProps={{
              min: 8,
              max: 23,
            }}
            InputLabelProps={{ shrink: !!formik.values.toHours }}
            error={!!formik.errors.toHours}
            helperText={!!formik.errors.toHours ? formik.errors.toHours : ""}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            autoComplete="given-name"
            name="countryExtraTime"
            fullWidth
            id="countryExtraTime"
            label="Country extra time"
            autoFocus
            value={formik.values.countryExtraTime}
            onChange={formik.handleChange}
            type="number"
            inputProps={{
              min: 0,
              max: 12,
              inputProps: {
                inputmode: "numeric",
              },
            }}
            InputLabelProps={{ shrink: !!formik.values.countryExtraTime }}
            error={!!formik.errors.countryExtraTime}
            helperText={
              !!formik.errors.countryExtraTime
                ? formik.errors.countryExtraTime
                : ""
            }
          />
        </Grid>
      </Grid>
      <SectionActionBar
        onReset={() => formik.resetForm()}
        loading={loading}
        shouldDisplayDelete={false}
        formDirty={formik.dirty}
        formTouched={formik.touched}
      />
    </form>
  );
}

export default NotificationsSettings;
