import React from "react";
import Parse from "parse";
import { IApp } from "../interfaces";
export async function getAppList(): Promise<IApp[]> {
  try {
    const parseQuery: Parse.Query = new Parse.Query("App");
    const dataFromParse = await parseQuery.findAll();
    const allApps: IApp[] = dataFromParse.map((appAsParseObj) => {
      const { displayAppName, logicAppName, packageId } =
        appAsParseObj.attributes;
      const id = appAsParseObj.id;
      return {
        id,
        displayAppName,
        logicAppName,
        packageId,
      };
    });
    return allApps;
  } catch (e) {
    console.error("error in getting app settings", e);
    return [];
  }
}
export async function getApplication(appId: any): Promise<any> {
  try {
    const parseQuery: Parse.Query = new Parse.Query("App");
    const dataFromParse = await parseQuery.get(appId);

    const {
      displayAppName,
      logicAppName,
      packageId,
      keywords,
      newsPushTopic,
      pushTimeFrame,
      displayInterstitialAd,
      displayBannerAd,
      adIds,
    } = dataFromParse.attributes;
    const app: IApp = {
      id: appId,
      displayAppName,
      logicAppName,
      packageId,
      keywords,
      newsPushTopic,
      pushTimeFrame,
      displayInterstitialAd,
      displayBannerAd,
      adIds,
    };
    return app;
  } catch (e) {
    console.error("error in getting app settings", e);
    return null;
  }
}

export async function createApp(appToAdd: IApp) {
  const App = Parse.Object.extend("App");
  const newApp = new App();
  let { packageId, newsPushTopic, logicAppName, displayAppName, keywords } =
    appToAdd;
  newApp.set("displayAppName", displayAppName);
  newApp.set("packageId", packageId);
  newApp.set("newsPushTopic", newsPushTopic);
  newApp.set("logicAppName", logicAppName);
  newApp.set("keywords", keywords);
  const defaultPushTimeFrame = {
    fromHours: 8,
    toHours: 23,
    countryExtraTime: 2,
  };

  newApp.set("pushTimeFrame", defaultPushTimeFrame);

  try {
    const app = await newApp.save();
    console.log(app);
    return app.id;
  } catch (error: any) {
    throw "Error in creating new app";
  }
}

export async function updateAppGeneralInfo(appToUpdate: IApp) {
  let { id, newsPushTopic, displayAppName, keywords } = appToUpdate;

  const parseQuery: Parse.Query = new Parse.Query("App");
  const appFromParse = await parseQuery.get(id || "");
  appFromParse.set("displayAppName", displayAppName);
  appFromParse.set("newsPushTopic", newsPushTopic);
  appFromParse.set("keywords", keywords);
  try {
    await appFromParse.save();
    return true;
  } catch (error: any) {
    throw "Error in updating the app";
  }
}

export async function deleteAppFromDB(id: string) {
  const parseQuery: Parse.Query = new Parse.Query("App");
  const appFromParse = await parseQuery.get(id || "");
  try {
    await appFromParse.destroy();
    return true;
  } catch (error) {
    throw "Failed deleting app";
  }
}
