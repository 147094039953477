import Parse from "parse";
import { IDataResource } from "../interfaces";

const NewsSource = Parse.Object.extend("NewsSource");

export async function getDataResourcesAPI(): Promise<IDataResource[]> {
  try {
    const query = new Parse.Query("NewsSource");
    const dataFromParse = await query.findAll();
    const allDataResources: IDataResource[] = dataFromParse.map(
      (dataSourceAsParseObj) => {
        const id = dataSourceAsParseObj.id;
        const {
          isDisabled,
          isRSS,
          name,
          mapping,
          endpoint,
          isGoogleNewRss,
          language,
          isNewsAPI,
          category,
          subcategory,
        } = dataSourceAsParseObj.attributes;
        return {
          id,
          isDisabled,
          isRSS,
          name,
          mapping,
          endpoint,
          isGoogleNewRss,
          language,
          isNewsAPI,
          category,
          subcategory,
        };
      }
    );
    return allDataResources;
  } catch (e) {
    console.error("error in getting news resources", e);
    return [];
  }
}
