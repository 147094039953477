import { IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export default function FilterInput({
  value,
  onChange,
  placeholder = "Search...",
}: any) {
  function handleClearClick() {
    onChange("");
  }
  return (
    <TextField
      id="standard-basic"
      variant="standard"
      onChange={(event) => onChange(event.target.value)}
      value={value}
      placeholder={placeholder}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            sx={{ visibility: !!value ? "visible" : "hidden" }}
            onClick={handleClearClick}
          >
            <ClearIcon />
          </IconButton>
        ),
      }}
    />
  );
}
