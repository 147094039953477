import Parse from "parse";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { getDataResourcesAPI } from "../../../common/api/news-source.api";
import { IDataResource } from "../../../common/interfaces";

function DataResourcesGrid() {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState<IDataResource[]>([]); // Set rowData to Array of Objects, one Object per Row

  const columnDefs = [
    { field: "name", filter: true, editable: true },
    { field: "endpoint", filter: true, editable: true },
    { field: "category", filter: true, editable: true },
    { field: "subcategory", filter: true, editable: true },
    { field: "language", editable: true },
    { field: "isGoogleNewRss", editable: true },
    { field: "isNewsAPI", editable: true },
  ];

  useEffect(() => {
    async function getDataResources() {
      const allDataResources: IDataResource[] = await getDataResourcesAPI();
      setRowData(allDataResources);
    }
    getDataResources();
  }, []);

  const onCellEditingStopped = async (event: any) => {
    const { data, colDef, newValue } = event;
    const { id } = data;
    const { field } = colDef;

    const NewsSource = Parse.Object.extend("NewsSource");
    const query = new Parse.Query(NewsSource);
    const source = await query.get(id);
    source.set(field, newValue);
    source.save();
  }

  return (
    <div className="ag-theme-alpine" style={{ width: 500, height: 500 }}>
      <AgGridReact
        ref={gridRef as any} // Ref for accessing Grid's API
        rowData={rowData} // Row Data for Rows
        columnDefs={columnDefs} // Column Defs for Columns
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        rowSelection="multiple" // Options - allows click selection of rows
        onCellEditingStopped={onCellEditingStopped}
      />
    </div>
  );
}
export default DataResourcesGrid;
