import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function SectionActionBar({
  isNew = true,
  submitTextButton = "Save",
  onReset,
  loading,
  shouldDisplayDelete = false,
  formDirty = false,
  formTouched = false,
  onDelete = null,
}: any) {
  function handleDelete() {
    if (window.confirm("Are you sure you want to delete?") == true) {
      onDelete();
    }
  }
  return (
    <Box
      sx={{ marginTop: "19px" }}
      display="flex"
      justifyContent={isNew ? "end" : "space-between"}
    >
      {shouldDisplayDelete && (
        <Box>
          <Button
            onClick={handleDelete}
            disabled={loading}
            sx={{ color: "red", borderColor: "red" }}
            variant="outlined"
          >
            Delete
          </Button>
        </Box>
      )}
      <Box display="flex" justifyContent="start">
        <Button
          sx={{ marginRight: "10px" }}
          type="reset"
          variant="outlined"
          onClick={onReset}
          disabled={loading || !formDirty}
        >
          Cancel
        </Button>
        {loading ? (
          <LoadingButton sx={{ minWidth: "85px" }} loading variant="outlined" />
        ) : (
          <Button
            disabled={loading || !formDirty}
            type="submit"
            variant="contained"
          >
            {submitTextButton}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default SectionActionBar;
