import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import { AlertContext } from "../../common/contexts";
import { IAlertObj } from "../../common/interfaces";

export default function FeedbackProvider({ children }: any) {
  const [alertObj, setAlertObj] = useState<IAlertObj>({ show: false });

  function showSuccess(msg: string) {
    setAlertObj({ show: true, message: msg, severity: "success" });
  }
  function showError(msg: string) {
    setAlertObj({ show: true, message: msg, severity: "error" });
  }

  function hideFeedback() {
    setAlertObj((prevAlertObj: IAlertObj) => {
      return { show: false, severity: prevAlertObj.severity, message: "" };
    });
  }

  return (
    <AlertContext.Provider
      value={{
        showSuccess,
        showError,
      }}
    >
      <Snackbar
        open={alertObj.show}
        autoHideDuration={5000}
        onClose={hideFeedback}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={hideFeedback}
          severity={alertObj.severity}
          sx={{ width: "100%" }}
        >
          {alertObj.message}
        </Alert>
      </Snackbar>
      {children}
    </AlertContext.Provider>
  );
}
