import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { IApp } from "../../../common/interfaces";

function Application({ app }: any) {
  const { displayAppName, logicAppName, id } = app as IApp;
  return (
    <Link to={`/apps/${id}`}>
      <Card sx={{ minWidth: 50, height: 200 }}>
        <CardContent sx={{ height: 100 }}>
          <Typography
            variant="h5"
            fontWeight={800}
            color="text.primary"
            gutterBottom
          >
            {displayAppName}
          </Typography>
          <Typography variant="h5" component="div">
            {logicAppName}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}

export default Application;
