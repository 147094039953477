import React from "react";
import Parse from "parse";
import { IApp, IPushTimeFrame } from "../interfaces";

export async function updateAppNotificationSettings(
  id: string,
  notificationSettings: IPushTimeFrame
) {
  let { fromHours, toHours, countryExtraTime } = notificationSettings;

  const parseQuery: Parse.Query = new Parse.Query("App");
  const appFromParse = await parseQuery.get(id || "");
  const timeFrameObj = {
    fromHours,
    toHours,
    countryExtraTime,
  };
  appFromParse.set("pushTimeFrame", timeFrameObj);
  try {
    await appFromParse.save();
    return true;
  } catch (error: any) {
    throw "Error in updating the app";
  }
}
