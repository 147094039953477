import React from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./App.css";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { Container } from "@mui/system";
import { Route, Routes } from "react-router-dom";
import Auth from "./pages/Auth/Auth";
import AppRoot from "./pages/AppRoot/AppRoot";
import ProtectedRoutes from "./components/ProtectedRoutes/ProtectedRoutes";
import DataResources from "./pages/DataResources/DataResources";

function App() {
  return (
    <div className="App">
      <Container maxWidth={false}>
        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route path="/" element={<AppRoot />} />
            <Route path="/apps/*" element={<AppRoot />} />
            <Route path="/resources" element={<DataResources />} />
          </Route>
          <Route path="/auth" element={<Auth />} />
        </Routes>
      </Container>
    </div>
  );
}

export default App;
