import * as Yup from "yup";

export const generalInfoSchema = Yup.object().shape({
  displayAppName: Yup.string().required("Required field"),
  keywordsStr: Yup.string().required("Required field"),
  logicAppName: Yup.string().required("Required field"),
  newsPushTopic: Yup.string().required("Required field"),
  packageId: Yup.string()
    .required("Required field")
    .matches(/^com\.[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*$/, "invalid package id"),
});
export const notificationSchema = Yup.object().shape({
  countryExtraTime: Yup.number()
    .max(12, "Maximum value is 12")
    .min(0, "Minimum value is 0"),
  fromHours: Yup.number()
    .max(23, "Maximum value is 23")
    .min(8, "Minimum value is 8"),
  toHours: Yup.number()
    .max(23, "Maximum value is 23")
    .min(8, "Minimum value is 8"),
});
