import Grid from "@mui/material/Grid";

import ContentzMenuDrawer from "../../components/ContentzMenuDrawer/ContentzMenuDrawer";
import { Route, RouterProvider, Routes } from "react-router-dom";
import AppDetails from "../AppDetails/AppDetails";
import AppList from "../ContentzApps/AppList/AppList";
import ProtectedRoutes from "../../components/ProtectedRoutes/ProtectedRoutes";
import FeedbackProvider from "../../components/FeedbackProvider/FeedbackProvider";
import Resources from "../DataResources/DataResources";

function AppRoot() {
  return (
    <ContentzMenuDrawer>
      <FeedbackProvider>
        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route path="/:id" element={<AppDetails />} />
            <Route path="/new" element={<AppDetails />} />
            <Route path="/" element={<AppList />} />
          </Route>
        </Routes>
      </FeedbackProvider>
    </ContentzMenuDrawer>
  );
}
export default AppRoot;
